import React from "react";
import { Link } from "react-router-dom";
import SideNav from '../../components/sidenav/sidenav';
import './homepage.css';
import '../../common/grid.css'
import cardIcon from '../../assets/integration-card-icon.svg';
import cardImage from '../../assets/homepage-card-image.svg';
import cardImage1 from '../../assets/Frame 2018776690.png';
import searchIcon from '../../assets/header/search-icon.svg';
import userIcon from '../../assets/header/user-icon.svg';
import Icon1 from '../../assets/App icon container-1.svg';
import Icon2 from '../../assets/App icon container-2.svg';
import Icon3 from '../../assets/App icon container-3.svg';
import Icon4 from '../../assets/App icon container-4.svg';
import Icon5 from '../../assets/App icon container-5.svg';
import Icon6 from '../../assets/App icon container-6.svg';
import Icon7 from '../../assets/App icon container-7.svg';
import Icon8 from '../../assets/App icon container-8.svg';

export default function Homepage() {
    return (
        <div className="app-wrapper">
            <SideNav />
            <div className="right-section-wrapper">
                <div className="section-header">
                    <h1>Customer Service Agent</h1>
                    <div className="header-icons">
                        <img src={searchIcon} alt="Search" />
                        <img src={userIcon} alt="User" />
                    </div>
                </div>
                <div className="homepage-container">
                    <h2>Welcome</h2>
                    <hr />
                    <div className="use-cases-card-wrapper">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="investigation-card">
                                    <div className="card-icon">
                                        <img src={cardIcon} alt="card-icon" />
                                    </div>
                                    <div className="card-details">
                                        <h3>IVA Assistance</h3>
                                        <div className="description-wrapper">
                                            <div className="card-description">
                                                <p>Create shared inboxes for teammates to work together.</p>
                                                <Link to="/ivr">
                                                    <button>Try Now</button>
                                                </Link>
                                            </div>
                                            <div className="card-image">
                                                <img src={cardImage} alt="card-image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="investigation-card">
                                    <div className="card-icon">
                                        <img src={cardIcon} alt="card-icon" />
                                    </div>
                                    <div className="card-details">
                                        <h3>Chat Assistance</h3>
                                        <div className="description-wrapper">
                                            <div className="card-description">
                                                <p>Create shared inboxes for teammates to work together.</p>
                                                <Link to="/conversations">
                                                    <button>Try Now</button>
                                                </Link>
                                            </div>
                                            <div className="card-image">
                                                <img src={cardImage1} alt="card-image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>
                        Select your required Use Case
                    </h2>
                    <p>Browse through the most commonly enabled plugins in Akira AI.</p>
                    <div className="integration-cards-wrapper">
                    <div className="row row-wrapper">
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={Icon1} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Retail</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Enhance customer shopping experiences with personalized support and seamless service across multiple channels.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={Icon2} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Hospitality</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Provide exceptional guest services with tailored recommendations and efficient issue resolution.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={Icon3} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Fitness</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Deliver personalized fitness guidance and support, ensuring customers achieve their health and wellness goals.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={Icon4} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Loyalty</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Provide exceptional guest services with tailored recommendations and efficient issue resolution.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-wrapper">
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={Icon5} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>E-commerce</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Streamline the online shopping experience by providing instant customer support, personalized product recommendations, and fast order tracking.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={Icon6} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Tourism</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Optimize customer service by providing personalized travel itineraries, real-time booking assistance, and post-trip support for seamless experiences.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={Icon7} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Banking</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Improve customer satisfaction with efficient account management, real-time transaction assistance, and personalized financial advice.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={Icon8} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Telecommunications</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Enhance customer support by offering quick resolution of technical issues, plan recommendations, and real-time service updates.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  
                </div>
            </div>
        </div>
    );
}
