import React from 'react';
import './sidenav.css';
import akiraLogoSmall from '../../assets/akira-ai-bot-image.svg';
import dashboardIcon from '../../assets/sidenav/dashboard-icon.svg';
import casesIcon from '../../assets/sidenav/cases-icon.svg';
import conversationIcon from '../../assets/sidenav/conversation-icon.svg';
import accountsIcon from '../../assets/sidenav/accounts-icon.svg';
import activityIcon from '../../assets/sidenav/recents-icon.svg';
import settingsIcon from '../../assets/sidenav/settings-icon.svg';

const SideNav = () => {
  return (
    <>
      {/* <div className="sidenav large-screen">
        <div className="logo-container">
          <img src={akiraLogo} alt="Logo" className="logo large" />
        </div>
        <ul className="menu">
          <li className="menu-item">
            <img src={dashboardIcon} alt="Dashboard" />
            <span>Dashboard</span>
          </li>
          <li className="menu-item">
            <img src={casesIcon} alt="Cases" />
            <span>Cases</span>
          </li>
          <li className="menu-item">
            <img src={conversationIcon} alt="Conversation" />
            <span>Conversations</span>
          </li>
          <li className="menu-item">
            <img src={accountsIcon} alt="Accounts" />
            <span>Accounts</span>
          </li>
          <li className="menu-item">
            <img src={activityIcon} alt="Recent Activity" />
            <span>Activity</span>
          </li>
          <li className="menu-item">
            <img src={settingsIcon} alt="Settings" />
            <span>Settings</span>
          </li>
        </ul>
      </div> */}

      <div className="sidenav small-screen">
        <div className="logo-container">
          <img src={akiraLogoSmall} alt="Small Logo" className="logo small" />
        </div>
        <ul className="menu">  
          <li className="menu-item">
            <img src={dashboardIcon} alt="Dashboard" />
          </li>
          <li className="menu-item">
            <img src={casesIcon} alt="Cases" />
          </li>
          <li className="menu-item">
            <img src={conversationIcon} alt="Conversation" />
          </li>
          <li className="menu-item">
            <img src={accountsIcon} alt="Accounts" />
          </li>
          <li className="menu-item">
            <img src={activityIcon} alt="Recent Activity" />
          </li>
          <li className="menu-item">
            <img src={settingsIcon} alt="Settings" />
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideNav;
