import React, { useState, useEffect, useRef } from "react";
import "./ivr.css";
import searchIcon from "../../assets/header/search-icon.svg";
import userIcon from "../../assets/header/user-icon.svg";
import SideNav from "../../components/sidenav/sidenav";
import axios from "axios";
import { REACT_APP_BASE_URL , REACT_APP_WEBSOCKET_URL } from '../../config';

const IVRPage = () => {
    const [conversations, setConversations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [transcript, setTranscript] = useState([]);
    const [userData, setUserData] = useState({
        name: "",
        language: "",
        service_type: "",
        location: "",
        brand: "",
    });
    const [conversationType, setConversationType] = useState("active"); 
    const websocketRef = useRef(null);

    useEffect(() => {
        const fetchConversations = async () => {
            setIsLoading(true);
            setError("");
            try {
                const response = await axios.get(REACT_APP_BASE_URL + `/conversations`, {
                    headers: {
                        accept: "application/json",
                    },
                });
                if (response.data.status === "success") {
                    const allConversations = response.data.data.conversations;
                    const filteredConversations = allConversations.filter((conversation) => {
                        if (conversationType === "active") {
                            return conversation.status === "new" || conversation.status === "transferred";
                        }
                        if (conversationType === "completed") {
                            return conversation.status === "resolved";
                        }
                        return false;
                    });
                    setConversations(filteredConversations);
                } else {
                    setError("Failed to load conversations.");
                }
            } catch (error) {
                console.error("Error fetching conversations:", error);
                setError("Error fetching conversations.");
            } finally {
                setIsLoading(false);
            }
        };
        fetchConversations();
    }, [conversationType]);

    useEffect(() => {
        if (selectedSessionId && conversationType === "active") {
            const savedTranscript = JSON.parse(localStorage.getItem(`transcript_${selectedSessionId}`)) || [];
            setTranscript(savedTranscript);
            if (websocketRef.current) {
                websocketRef.current.close();
            }
            const websocketUrl = REACT_APP_WEBSOCKET_URL + `/chat?session_id=${selectedSessionId}`;
            websocketRef.current = new WebSocket(websocketUrl);
            websocketRef.current.onmessage = (event) => {
                const message = JSON.parse(event.data);
                if (message.type === "message") {
                    const newMessage = {
                        role: message.data.role,
                        content: message.data.content,
                    };
                    setTranscript((prev) => {
                        const updatedTranscript = [...prev, newMessage];
                        localStorage.setItem(`transcript_${selectedSessionId}`, JSON.stringify(updatedTranscript));
                        return updatedTranscript;
                    });
                    if (message.data) {
                        setUserData({
                            name: message.data.name || "",
                            language: message.data.language || "",
                            service_type: message.data.service_type || "",
                            location: message.data.location || "",
                            brand: message.data.brand || "",
                        });
                    }
                }
            };
            websocketRef.current.onerror = (error) => {
                console.error("WebSocket error:", error);
            };
            websocketRef.current.onclose = () => {
                console.log("WebSocket connection closed.");
            };
        }
        return () => {
            if (websocketRef.current) {
                websocketRef.current.close();
            }
        };
    }, [selectedSessionId, conversationType]);

    const fetchCompletedConversationDetails = async (caseId) => {
        try {
            setIsLoading(true);
            const response = await axios.get(REACT_APP_BASE_URL + `/cases?case_id=${caseId}`, {
                headers: {
                    accept: "application/json",
                },
            });
            if (response.data) {
                const { customer_name, language, service_type, location, brand, chat_history } = response.data;
                setUserData({
                    name: customer_name || "N/A",
                    language: language || "N/A",
                    service_type: service_type || "N/A",
                    location: location || "N/A",
                    brand: brand || "N/A",
                });
                setTranscript(
                    chat_history.map((message) => ({
                        role: message.role,
                        content: message.content,
                    }))
                );
            } else {
                setError("Failed to fetch completed conversation details.");
            }
        } catch (error) {
            console.error("Error fetching completed conversation details:", error);
            setError("Error fetching completed conversation details.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleTypeChange = (event) => {
        setConversationType(event.target.value);
        setUserData({
            name: "",
            language: "",
            service_type: "",
            location: "",
            brand: "",
        });
        setTranscript([]);
        setSelectedSessionId(null); 
    };
    
    const handleConversationClick = (sessionId) => {
        setSelectedSessionId(sessionId);
        if (conversationType === "completed") {
            fetchCompletedConversationDetails(sessionId);
        } else {
            setUserData({
                name: "",
                language: "",
                service_type: "",
                location: "",
                brand: "",
            });
            setTranscript([]);
        }
    };

    return (
        <div className="page-container">
            <SideNav />
            <div className="right-section-wrapper">
                <div className="section-header">
                    <h1>IVA - Self Assistance</h1>
                    <div className="header-icons">
                        <img src={searchIcon} alt="Search" />
                        <img src={userIcon} alt="User" />
                    </div>
                </div>
                <div className="main-section-wrapper">
                    <div className="column chats-section">
                        <div className="chats-header">
                            <h3>Conversations</h3>
                            <select
                                className="conversation-type-dropdown"
                                value={conversationType}
                                onChange={handleTypeChange}
                            >
                                <option value="active">Active</option>
                                <option value="completed">Completed</option>
                            </select>
                        </div>
                        <hr />
                        {isLoading && <p>Loading conversations...</p>}
                        {error && <p className="error">{error}</p>}
                        {!isLoading &&
                            !error &&
                            conversations.length > 0 &&
                            conversations.map((conversation) => (
                                <div
                                    className={`case-item ${conversation.session_id === selectedSessionId ? "active" : ""}`}
                                    key={conversation.session_id}
                                    onClick={() => handleConversationClick(conversation.session_id)}
                                >
                                    <img
                                        src={REACT_APP_BASE_URL + `${conversation.profile_icon}`}
                                        alt={conversation.customer_name}
                                        className="avatar"
                                    />
                                    <div className="case-details">
                                        <h4>{conversation.customer_name}</h4>
                                        <p>{conversation.last_user_message}</p>
                                    </div>
                                </div>
                            ))}
                        {!isLoading && !error && conversations.length === 0 && (
                            <p>No {conversationType} conversations at the moment.</p>
                        )}
                    </div>
                    <div className="column ivr-details-column">
                        <div className="patient-details-wrapper">
                            <h3>Patient Details</h3>
                            <hr />
                            <p><strong>Name:</strong> {userData.name || "N/A"}</p>
                            <p><strong>Language:</strong> {userData.language || "N/A"}</p>
                            <p><strong>Service Type:</strong> {userData.service_type || "N/A"}</p>
                            <p><strong>Location:</strong> {userData.location || "N/A"}</p>
                            <p><strong>Brand:</strong> {userData.brand || "N/A"}</p>
                        </div>
                    </div>
                    <div className="column ivr-details-column transcript-column">
                        <div className="transcript-wrapper">
                            <h3>Transcript</h3>
                            <hr />
                            {transcript.length > 0 ? (
                                transcript.map((message, index) => (
                                    <p key={index}>
                                        <strong>{message.role === "assistant" ? "Agent" : "User"}:</strong>{" "}
                                        {message.content}
                                    </p>
                                ))
                            ) : (
                                <p>No messages yet for this conversation.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IVRPage;

