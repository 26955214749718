// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidenav {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    border-right: 3px solid #EBEDF3;
    padding: 20px;
    background-color: #EBEDF3;
    position: fixed;
    z-index: 1111;
}

.logo-container {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
}

.logo {
    width: 100%;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.menu-item img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.menu-item span {
    display: inline;
}

.small-screen {
    width: 120px;
}

.small-screen .logo {
    width: 60px;
}

.small-screen .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;
}

.small-screen .menu-item img {
    width: 28px;
    height: 28px;
}

@media (max-width: 1440px) {
    .large-screen {
        display: none;
    }

    .small-screen {
        display: flex;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/sidenav/sidenav.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,+BAA+B;IAC/B,aAAa;IACb,yBAAyB;IACzB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".sidenav {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    height: 100vh;\n    border-right: 3px solid #EBEDF3;\n    padding: 20px;\n    background-color: #EBEDF3;\n    position: fixed;\n    z-index: 1111;\n}\n\n.logo-container {\n    width: 100%;\n    margin-bottom: 40px;\n    text-align: center;\n}\n\n.logo {\n    width: 100%;\n}\n\n.menu-item {\n    display: flex;\n    align-items: center;\n    padding: 15px 20px;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.menu-item img {\n    width: 24px;\n    height: 24px;\n    margin-right: 10px;\n}\n\n.menu-item span {\n    display: inline;\n}\n\n.small-screen {\n    width: 120px;\n}\n\n.small-screen .logo {\n    width: 60px;\n}\n\n.small-screen .menu-item {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 16px 0;\n    cursor: pointer;\n}\n\n.small-screen .menu-item img {\n    width: 28px;\n    height: 28px;\n}\n\n@media (max-width: 1440px) {\n    .large-screen {\n        display: none;\n    }\n\n    .small-screen {\n        display: flex;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
