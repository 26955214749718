import React from "react";
import SideNav from "../../components/sidenav/sidenav";
import searchIcon from '../../assets/header/search-icon.svg';
import userIcon from '../../assets/header/user-icon.svg';
import './dashboard.css'

export default function Dashboard() {
    return (
        <div className="app-wrapper">
            <SideNav />
            <div className="right-section-wrapper">
                <div className="section-header">
                    <h1>Customer Service Agent</h1>
                    <div className="header-icons">
                        <img src={searchIcon} alt="Search" />
                        <img src={userIcon} alt="User" />
                    </div>
                </div>
            </div>
        </div>
    )
}