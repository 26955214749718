import React, { useState, useRef, useEffect } from "react";
import "./CasesPage.css";
import searchIcon from "../../assets/header/search-icon.svg";
import userIcon from "../../assets/header/user-icon.svg";
import botIcon from "../../assets/bot-icon.svg";
import profile1 from "../../assets/profile-1.svg";
import callIcon from "../../assets/call-icon.svg";
import sendButton from "../../assets/send-icon.svg";
import SideNav from "../../components/sidenav/sidenav";
import { REACT_APP_BASE_URL, REACT_APP_WEBSOCKET_URL } from '../../config';

const CasesPage = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [conversations, setConversations] = useState([]);
  const [activeSessionId, setActiveSessionId] = useState(null);
  const [caseDetails, setCaseDetails] = useState(null);
  const [socket, setSocket] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await fetch(REACT_APP_BASE_URL + "/supervisor-cases");
        const data = await response.json();
        if (data && data.cases) {
          const baseURL = REACT_APP_BASE_URL;
          const mappedConversations = data.cases.map((item) => ({
            session_id: item.session_id,
            profile_icon: item.profile_icon
              ? `${baseURL}${item.profile_icon}`
              : profile1,
            customer_name: item.customer_name,
            last_user_message: item.last_message,
          }));
          setConversations(mappedConversations);
        }
      } catch (error) {
        console.error("Error fetching conversations:", error);
      }
    };

    fetchConversations();
  }, []);

  const handleConversationClick = (sessionId) => {
    setActiveSessionId(sessionId);
    setMessages([]);
    connectWebSocket(sessionId);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "" && socket) {
      const messageData = {
        type: "message",
        message: newMessage,
        sender: "user",
        timestamp: new Date().toISOString(),
      };
      socket.send(JSON.stringify(messageData));

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: "user",
          text: newMessage,
          icon: userIcon,
          timestamp: new Date().toISOString(),
        },
      ]);
      setNewMessage("");
    }
  };

  const connectWebSocket = (sessionId) => {
    if (socket) socket.close();

    const ws = new WebSocket(
      REACT_APP_WEBSOCKET_URL + `/self-assistance?session_id=${sessionId}`
    );

    ws.onopen = () => {
      console.log("WebSocket connection established.");
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data && data.data) {
          const messageData = data.data;
          const isUserMessage = messageData.role === "user";
          const newMessage = {
            type: isUserMessage ? "user" : "assistant",
            text: messageData.content,
            icon: isUserMessage ? userIcon : botIcon,
            timestamp: messageData.timestamp || new Date().toISOString(),
            sessionId: messageData.session_id,
          };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
          if (messageData.session_id === activeSessionId) {
            const updatedCaseDetails = {
              name: messageData.name || caseDetails?.name || "Unknown",
              language: messageData.language || caseDetails?.language || "Unknown",
              serviceType: messageData.service_type || caseDetails?.serviceType || "Unknown",
              brand: messageData.brand || caseDetails?.brand || "Unknown",
              priority: messageData.priority || caseDetails?.priority || "Unknown",
              lastInteraction: new Date(messageData.timestamp).toLocaleString(),
              customerFeedback: messageData.customer_feedback || caseDetails?.customerFeedback || "No feedback available",
            };
            setCaseDetails(updatedCaseDetails);
          }
        }
      } catch (error) {
        console.error("Error processing WebSocket message:", error);
      }
    };
   
    

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    setSocket(ws);
  };

  return (
    <div className="page-container">
      <SideNav />
      <div className="right-section-wrapper">
        <div className="section-header">
          <h1>Chat - Self Assistance</h1>
          <div className="header-icons">
            <img src={searchIcon} alt="Search" />
            <img src={userIcon} alt="User" />
          </div>
        </div>
        <div className="main-section-wrapper">
          <div className="column chats-section">
            <h3>Conversations</h3>
            <hr />
            {conversations.map((conversation) => (
              <div
                className="case-item"
                key={conversation.session_id}
                onClick={() => handleConversationClick(conversation.session_id)}
              >
                <img
                  src={conversation.profile_icon}
                  alt={conversation.customer_name}
                  className="avatar"
                />
                <div className="case-details">
                  <h4>{conversation.customer_name}</h4>
                  <p>{conversation.last_user_message}</p>
                </div>
              </div>
            ))}
            {conversations.length === 0 && <p>No active conversations at the moment.</p>}
          </div>
          <div className="chat-container">
            <header className="chat-header">
              <div className="profile">
                <img
                  src={profile1}
                  alt="User Profile"
                  className="profile-img"
                />
                <h3>Active Chat</h3>
              </div>
              <div className="call-icon">
                <img src={callIcon} alt="Call Icon" />
              </div>
            </header>
            <main className="chat-body">
              <div className="messages">
                {messages
                  .filter((message) => message.sessionId === activeSessionId)
                  .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
                  .map((message, index) => (
                    <div
                      key={index}
                      className={`message ${message.type === "user" ? "message-left" : "message-right"}`}
                    >
                      <img
                        src={message.icon} 
                        alt="Profile Icon"
                        className="message-icon"
                      />
                      <div className="message-content">
                        <div className="message-text">{message.text}</div>
                        <div className="message-timestamp">{new Date(message.timestamp).toLocaleString()}</div>
                      </div>
                    </div>
                  ))}

                {messages.filter((message) => message.sessionId === activeSessionId).length === 0 && (
                  <p>No messages to display for this conversation.</p>
                )}
                <div ref={messagesEndRef} />
              </div>
            </main>

            <footer className="message-input">
              <input
                type="text"
                placeholder="Type a message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <div className="actions">
                <img
                  src={sendButton}
                  alt="Send Message"
                  className="send-icon"
                  onClick={handleSendMessage}
                />
              </div>
            </footer>
          </div>
          <div className="column details-column">
            <div className="patient-details-wrapper">
              <h3>Case Details</h3>
              <hr />
              {caseDetails ? (
                <>
                  <p><strong>Name:</strong> {caseDetails.name}</p>
                  <p><strong>Language:</strong> {caseDetails.language}</p>
                  <p><strong>Service Type:</strong> {caseDetails.serviceType}</p>
                  <p><strong>Brand:</strong> {caseDetails.brand}</p>
                  <p><strong>Priority:</strong> {caseDetails.priority}</p>
                  <p><strong>Last Interaction:</strong> {caseDetails.lastInteraction}</p>
                  <p><strong>Customer Feedback:</strong> {caseDetails.customerFeedback}</p>
                </>
              ) : (
                <p>Select a conversation to view details.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasesPage;
