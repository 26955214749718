// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ivr-details-column {
    flex: 1 1 35%;
    max-width: 35%;
  }

  .ivr-details-column p{
    margin: 0.5rem 0;
    font-size: 1.6rem;
  }
  .ivr-details-column h2 {
    margin-top: 2rem; 
  }

  .transcript-column {
    border-left: 3px solid #EBEDF3;
  }

  .case-item{
    padding: 10px;
    border-radius: 8px;
  }

  .case-item:hover {
    background-color: #F4F7FF; 
  }
  
  .case-item.active {
    background-color: #F4F7FF; 
  }

  @media screen and (max-width: 768px) {
    .ivr-details-column h2 {
      margin-top: 1.5rem;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/ivrpage/ivr.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;EACA;IACE,gBAAgB;EAClB;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE;MACE,kBAAkB;IACpB;EACF","sourcesContent":[".ivr-details-column {\n    flex: 1 1 35%;\n    max-width: 35%;\n  }\n\n  .ivr-details-column p{\n    margin: 0.5rem 0;\n    font-size: 1.6rem;\n  }\n  .ivr-details-column h2 {\n    margin-top: 2rem; \n  }\n\n  .transcript-column {\n    border-left: 3px solid #EBEDF3;\n  }\n\n  .case-item{\n    padding: 10px;\n    border-radius: 8px;\n  }\n\n  .case-item:hover {\n    background-color: #F4F7FF; \n  }\n  \n  .case-item.active {\n    background-color: #F4F7FF; \n  }\n\n  @media screen and (max-width: 768px) {\n    .ivr-details-column h2 {\n      margin-top: 1.5rem;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
